<template>
  <div class="card" v-if="alerts.data != null">
    <div class="card-body">
      <!-- Filter  -->
      <div class="row">
        <div class="col-md-3">
          <h4>My Alerts</h4>
          <div class="form-group">
            <datetime
              v-model="DateTimeFilter"
              @input="Search"
              :week-start="7"
              placeholder="DateTime"
              format=" 'On Or After 'yyyy-MM-dd"
              input-class="col-md-12 form-control"
              input-style="font-size: 12px;"
            >
            </datetime>
          </div>

          <div class="form-group">
            <multiselect
              v-model="selectedAlertTypeFilter"
              :options="alertTypes"
              @input="Search"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Alert Types"
              Value="Code"
              label="displayName"
              track-by="Code"
              :preselect-first="false"
            >
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length > 2 &amp;&amp; !isOpen"
                  >{{ values.length }} Types Selected</span
                ></template
              >
            </multiselect>
          </div>
        </div>

        <div class="form-group col-md-4 mt-4">
          <button
            class="btn col-md-1 float-left mr-3 noFocusBorder"
            @click="resetFilters"
          >
            <i class="fa fa-redo-alt"></i>
          </button>

          <input
            type="search"
            class="form-control col-md-10"
            @change="Search"
            v-model="searchText"
            placeholder="Search"
          />
        </div>

        <div class="form-group col-md-5 text-right">
          <h4>Alert Counters</h4>
          <div class="badge badge-secondary col-md-3 ml-1">
            <span class="AlertCountersText">Tag Arlerts</span>
            <span style="float: right"> {{ TagAlerts }}</span>
          </div>
          <div class="badge badge-secondary col-md-3 ml-1">
            <span class="AlertCountersText">Expired Ca: </span>
            <span style="float: right"> {{ ExpiredCaregiver }}</span>
          </div>
          <div class="badge badge-secondary col-md-3 ml-1">
            <span class="AlertCountersText">ExpiredClient Care Plan: </span>
            <span style="float: right"> {{ ExpiredClientCarePlan }}</span>
          </div>
          <div class="badge badge-secondary col-md-3 ml-1">
            <span class="AlertCountersText">Follow Up Call: </span>
            <span style="float: right"> {{ FollowUpCall }}</span>
          </div>
          <div class="badge badge-secondary col-md-3 ml-1">
            <span class="AlertCountersText">Marketing Meetings: </span>
            <span style="float: right"> {{ MarketingMeetings }}</span>
          </div>
          <div class="badge badge-secondary col-md-3 ml-1">
            <span class="AlertCountersText">Telephone Messages </span>
            <span style="float: right"> {{ TelephoneMessages }}</span>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Alert Type</th>
          <th>Description</th>
          <th>DateTime</th>
          <th>Caregiver</th>
          <th>Clinet</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in alerts.data" :key="item.Id">
          <td
            :style="
              'background-color: ' + getAlertTypeColor(item.AlertType.Code)
            "
          >
            {{ item.AlertType.Name }}
          </td>
          <td>
            <p>{{ item.Description }}</p>
          </td>
          <td>{{ item.DateTime }}</td>

          <td>
            <span
              v-for="provider in item.CaregiversList"
              :key="provider.Id"
              class="spanCSV"
            >
              <router-link :to="`/providers/${provider.Id}`"
                >{{ provider.Name }}
              </router-link>
            </span>
          </td>

          <td>
            <span
              v-for="client in item.ClientsList"
              :key="client.Id"
              class="spanCSV"
            >
              <router-link :to="`/providers/${client.Id}`"
                >{{ client.Name }}
              </router-link>
            </span>
          </td>
          <td class="">
            <router-link :to="`/alerts/${item.Id}`" class="actionsSeperator"
              >View
            </router-link>
            <!-- would it be a modal or a page ? -->
            <router-link :to="`/alerts/${item.Id}`" class="actionsSeperator"
              >Remove </router-link
            ><!-- would it be a modal or a page ? -->
            <router-link :to="`/alerts/${item.Id}`" class="actionsSeperator"
              >Hide </router-link
            ><!-- would it be a modal or a page ? -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import { Datetime } from "vue-datetime";
export default {
  name: "Listalerts",
  components: {
    Multiselect,
    datetime: Datetime,
  },

  data() {
    return {
      searchText: "",

      //counters
      TagAlerts: "0",
      ExpiredCaregiver: "0",
      ExpiredClientCarePlan: "0",
      FollowUpCall: "0",
      MarketingMeetings: "0",
      TelephoneMessages: "0",
      selectedAlertTypeFilter: [],
      alertTypes: [
        {
          displayName: "Tag Alert",
          Code: "TagAlerts",
          Count: "3",
          color: "#ccc",
        },
        {
          displayName: "Expired Caregiver",
          Code: "ExpiredCaregiver",
          Count: "0",
          color: "#f24949",
        },

        {
          displayName: "Expired Client Care Plan",
          Code: "ExpiredClientCarePlan",
          Count: "3",
          color: "#0f0",
        },
        {
          displayName: "Follow Up Call",
          Code: "FollowUpCall",
          Count: "3",
          color: "#7f7ffc",
        },
        {
          displayName: "Marketing Meetings",
          Code: "MarketingMeetings",
          Count: "3",
          color: "#0ff",
        },
        {
          displayName: "Telephone Messages",
          Code: "TelephoneMessages",
          Count: "4",
          color: "#d179d1",
        },
      ],
      DateTimeFilter: "",
    };
  },
  computed: {
    ...mapState(["alerts"]),
  },
  mounted() {
    this.$store.dispatch("alerts/get");
  },
  created() {
    this.gettingTagsFromAlerts(this.alerts.data);
  },

  methods: {
    getAlertTypeColor(alertCode) {
      var result = this.alertTypes.filter(function (alertType) {
        if (alertType.Code == alertCode) {
          return alertType.color;
        }
      });
      if (result != null) {
        return result[0].color;
      }
    },

    gettingTagsFromAlerts(alertsData) {
      var outerThis = this;
      this.TagAlerts = 0;
      this.ExpiredCaregiver = 0;
      this.ExpiredClientCarePlan = 0;
      this.FollowUpCall = 0;
      this.MarketingMeetings = 0;
      this.TelephoneMessages = 0;
      alertsData.forEach(function (item) {
        switch (item.AlertType.Code) {
          case "TagAlerts":
            outerThis.TagAlerts++;
            break;
          case "ExpiredCaregiver":
            outerThis.ExpiredCaregiver++;
            break;
          case "ExpiredClientCarePlan":
            outerThis.ExpiredClientCarePlan++;
            break;
          case "FollowUpCall":
            outerThis.FollowUpCall++;
            break;
          case "MarketingMeetings":
            outerThis.MarketingMeetings++;
            break;

          case "TelephoneMessages":
            outerThis.TelephoneMessages++;
            break;
        }
      });
    },
    Search() {
      var selectedAlertCode = [];
      this.selectedAlertTypeFilter.forEach(function (item) {
        selectedAlertCode.push(item.Code);
      });

      this.$store.dispatch("alerts/search", [
        this.searchText,
        this.DateTimeFilter,
        selectedAlertCode,
      ]);
    },

    resetFilters() {
      this.selectedAlertTypeFilter = [];
      this.DateTimeFilter = "";
      this.searchText = "";
      this.Search();
    },
  },
};
</script>
