<template>
  <div class="container">
    <div class="row">
      <div class="col-9">
        <h1>My Alerts</h1>
      </div>
      <div class="col-3 text-right"></div>
    </div>
    <alert v-if="alerts.isLoading" />
    <alert v-if="!alerts.isLoading && alerts.data.length == 0">
      No Alerts
    </alert>
    <Listalerts />
    <!-- <AlertFilter /> -->
  </div>
</template>

<script>
import { default as Listalerts } from "./Listalerts";
// import { AlertFilter } from "./AlertFilter";

import { mapState } from "vuex";

export default {
  name: "my-alerts",
  computed: {
    ...mapState(["alerts"]),
  },
  components: {
    Listalerts,
    // AlertFilter
  },
};
</script>
